import React from "react";
import BackgroundLines from "../components/BackgroundLines";
import Header from "../components/Header";

function Layout(props) {
  return (
    <div className="mi-wrapper">
      <BackgroundLines />
      <Header />
      {props.children}
      <center><small><small><a rel="nofollow" target="_blank" href="https://www.iubenda.com/privacy-policy/7881646">Privacy Policy</a> - <a rel="nofollow" target="_blank" href="https://www.iubenda.com/privacy-policy/7881646/cookie-policy">Cookie Policy</a></small></small></center>
    </div>
  );
}

export default Layout;
