import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import { Link, NavLink } from 'react-router-dom';

function Header() {
    const [information, setInformation] = useState("");
    const [navigationToggler, setNavigationToggler] = useState(false);

    const handleNavigationToggler = () => {
        setNavigationToggler(!navigationToggler);
    }

    useEffect(() => {
        axios.get('/api/information')
            .then(response => {
                setInformation(response.data);
            })
    }, []
    )

    return (
        <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
            <button onClick={handleNavigationToggler} className="mi-header-toggler">
                {!navigationToggler ? <LineIcon name="menu" /> : <LineIcon name="close" />}
            </button>
            <div className="mi-header-inner">
                <div className="mi-header-image">
                    <Link to="/">
                        <img src={information.brandImage} alt="Brand Andrea Maccagno" />
                    </Link>
                </div>

                <ul className="mi-header-menu">
                    <li><NavLink title="Home Andrea Maccagno" alt="Home Andrea Maccagno" exact to="/"><span>Home</span></NavLink></li>
                    <li><NavLink title="About Andrea Maccagno" alt="About Andrea Maccagno" to="/about"><span>About</span></NavLink></li>
                    <li><NavLink title="Crediti Andrea Maccagno" alt="Crediti Andrea Maccagno" to="/crediti"><span>Crediti</span></NavLink></li>
                    <li><NavLink title="Immagini Andrea Maccagno" alt="Immagini Andrea Maccagno" to="/immagini"><span>Immagini</span></NavLink></li>
                    <li><NavLink title="Blog Andrea Maccagno" alt="Blog Andrea Maccagno" to="/blogs"><span>Blog</span></NavLink></li>
                    <li><NavLink title="Contatti Andrea Maccagno" alt="Contatti Andrea Maccagno" to="/contatti"><span>Contatti</span></NavLink></li>
                </ul>
                <p className="mi-header-copyright">&copy; {new Date().getFullYear()} <b><a title="Home Andrea Maccagno" alt="Andrea Maccagno" target="_blank" href="/">Andrea Maccagno</a></b></p>
            </div>
        </nav>
    )
}


export default Header;