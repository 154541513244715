import Mock from "../mock";

const database = {
  information: {
    name: 'Andrea Maccagno',
    aboutContent: "Produttore musicale. Arrangiatore. Bassista.",
    age: 30,
    phone: '+39 393 4253474',
    nationality: 'Italiana',
    language: 'Italiano, English, Español',
    email: '',
    address: 'mfh.one'+", "+ "varp-studio.com"+ ", " + "andreamaccagno.com",
    freelanceStatus: 'Never Half Step',
    socialLinks: {
      facebook: 'https://www.facebook.com/andreamaccagno01/',
      twitter: 'https://twitter.com/andreamaccagno1',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://www.instagram.com/andrea_maccagno/'
    },
    brandImage: '/images/Ritratto-Bassista-Andrea-Maccagno-brand.jpg',
    aboutImage: '/images/Ritratto-Bassista-Andrea-Maccagno.jpg',
    aboutImageLg: '/images/Ritratto-Bassista-Andrea-Maccagno-lg.jpg',
    cvfile: '/files/intervista-andrea-maccagno.pdf'
  },
  services: [
    {
      title: "Produttore musicale",
      icon: '',
      details: "Il produttore artistico è colui che cura il prodotto dal punto di vista artistico. Questo include la scelta del/degli arrangiatori, musicisti e studi di registrazione, allo scopo di ottenere il sound artisticamente giusto per l’artista."
    },
    {
      title: "Arrangiatore",
      icon: '',
      details: "L’arrangiamento è l’arte di strutturare, armonizzare la melodia, secondo lo stile e genere prescelto. Organizza e armonizza i vari strumenti in modo che siano leggibili e di supporto alla melodia, per ottenere il sound desiderato."
    },
    {
      title: "Bassista",
      icon: '',
      details: "Mi definirei il non-bassista: ho sempre avuto un approccio diverso, istintivo, eclettico e non convenzionale con il basso; molti pensano che questo sia solo uno strumento riempitivo, la verità è che determina più del 50% del brano. A volte anche di più"
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Per me sei un genio. Penso davvero che tu sia uno dei migliori arrangiatori italiani.",
      author: {
        name: 'G.N.',
        designation: 'Cantautore'
      }
    },
    {
      id: 2,
      content: "Bravo Andrea, ho ascoltato il pezzo e per me è perfetto così. Dobbiamo correre con gli altri per non rischiare di tardare [ops, forse questa parte non dovevo citarla].",
      author: {
        name: 'R.R.',
        designation: 'Editore e Produttore Discografico'
      }
    },
    {
      id: 3,
      content: "Ciao Andre, stavo riascoltando la canzone di A*** [titolo del brano non riportato], l'ultima versione che mi avevi mandato, lo sai che mi viene la pelle d'oca ogni volta che la ascolto?",
      author: {
        name: 'A.G.',
        designation: 'Paroliere e compositore'
      }
    },
    {
      id: 4,
      content: "Avevo tenuto questo brano come riempitivo. Dopo che l'hai arrangiato abbiamo deciso di usarlo come singolo",
      author: {
        name: 'P.C.',
        designation: 'Editore e Produttore Discografico'
      }
    }
  ],
  skills: [
    {
      title: "Comporre (brani musicali)",
      value: 22
    },
    {
      title: "Mixare e Masterizzare",
      value: 45
    },
    {
      title: "Registrare",
      value: 65
    },
    {
      title: "Comporre (colonne sonore)",
      value: 75
    },
    {
      title: "Arrangiare",
      value: 95
    },
    {
      title: "Suonare il basso",
      value: 100
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Andrea Maccagno",
      subtitle: "Foto del brand @ Studi di MFH 2016",
      imageUrl: "/images/Ritratto-Bassista-Andrea-Maccagno-small.jpg",
      largeImageUrl: ["/images/Ritratto-Bassista-Andrea-Maccagno-ufficiale.jpg"]
      //url: ''
    },
    {
      id: 2,
      title: "Pooh e Andrea Maccagno",
      subtitle: "Foto tour @ Dove Cominica il sole 2011",
      imageUrl: "/images/Pooh-Andrea Maccagno-Roby Facchinetti-Danilo-Ballo.jpg",
      largeImageUrl: [
        "/images/Andrea-Maccagno-Roby-Facchinetti-Danilo-Ballo-Pooh-lg.jpg",
        "/images/Andrea-Maccagno-Roby-Facchinetti-Pooh-1.jpg",
        "/images/Andrea-Maccagno-Vagnone-Ballo-Roby-Facchinetti-Pooh.jpg",
        "/images/Andrea-Maccagno-Pooh-Steve-Ferrone.jpg",
        "/images/Andrea-Maccagno-Cantele-Tramontani-Pooh.jpg",
        "/images/Andrea-Maccagno-Roby-Facchinetti-Pooh-Studio.jpg",
        "/images/Andrea-Maccagno-Ludovico-Vagnone-Pooh-Studio.jpg",
        "/images/Andrea-Maccagno-Roby-Facchinetti-Pooh-2.jpg",
        "/images/Andrea-Maccagno-Renato-Cantele-Pooh.jpg"
      ]
      //url: ''
    },
    {
      id: 3,
      title: "Andrea Maccagno Teatro Ariston",
      subtitle: "Reverie @ Sanremo - Teatro Ariston 2020",
      imageUrl: "/images/Andrea-Maccagno-Reverie-Sanremo-Rock-Teatro-Ariston.jpg",
      largeImageUrl: [
        "/images/Andrea-Maccagno-Ariston-Sanremo-Reverie-1.jpg",
        "/images/Andrea-Maccagno-Sanremo-Ariston-Reverie-Prove.jpg",
        "/images/Andrea-Maccagno-Ariston-Sanremo-Pass.jpg",
        "/images/Andrea-Maccagno-Sanremo-Reverie-Prove-2.jpg",
        "/images/Andrea-Maccagno-Sanremo-Prove-Reverie-1.jpg",
        "/images/Andrea-Maccagno-Sanremo-Rosenhouse-Studio.jpg",
        "/images/Andrea-Maccagno-Sanremo-Reverie-Prove-3.jpg"
      ]
      //url: ''
    },
    {
      id: 4,
      title: "Estopa e Andrea Maccagno",
      subtitle: "Tour & Rock In Rio @ Allenrock 2018",
      imageUrl: "/images/Andrea-Maccagno-Ludovico-Vagnone-Estopa.jpg",
      largeImageUrl: 
      [
        "/images/Andrea-Maccagno-Ludovico-Vagnone-Estopa-lg.jpg",
        "/images/Andrea-Maccagno-Estopa-2008-Roberto-Maccagno-Mixer.jpg",
        "/images/Andrea-Maccagno-Estopa-Rock-in-Rio-1.jpg",
        "/images/Andrea-Maccagno-Estopa-Rock-in-Rio-2.jpg",
        "/images/Andrea-Maccagno-Estopa-Rock-in-Rio-3.jpg",
        "/images/Andrea-Maccagno-Estopa-Allenrock-2008-pass.jpg"
      ]
      //url: ''
    },
    {
      id: 5,
      title: "Intervista TV Andrea Maccagno",
      subtitle: "Puntata su Andrea Maccagno @ Milano 2020",
      imageUrl: "/images/Andrea-Maccagno-Intervista-TV.jpg",
      largeImageUrl: 
      [
        "/images/Andrea-Maccagno-Intervista-TV-4.jpg",
        "/images/Andrea-Maccagno-Intervista-TV-5.jpg",
        "/images/Andrea-Maccagno-Intervista-TV-6.jpg",
        "/images/Andrea-Maccagno-Intervista-TV-7.jpg",
        "/images/Andrea-Maccagno-Intervista-TV-8.jpg",
        "/images/Andrea-Maccagno-Intervista-TV-3.jpg",
        "/images/Andrea-Maccagno-Intervista-TV-2.jpg",
      ]
    },
    {
      id: 6,
      title: "Nomadi e Andrea Maccagno",
      subtitle: "Composizione Brano @ Solo Esseri Umani 2021",
      imageUrl: "/images/Andrea-Maccagno-Nomadi-Crediti.jpg",
      largeImageUrl: 
      [
        "/images/Andrea-Maccagno-Nomadi-Crediti-lg.jpg",
        "/images/Andrea-Maccagno-Nomadi-Crediti-2.jpg",
        "/images/Andrea-Maccagno-Nomadi-Crediti-3.jpg"
      ]
    },
    {
      id: 7,
      title: "Roberto Gasparro e Andrea Maccagno",
      subtitle: "Colonna Sonora Film @ Lui È Mio Padre 2020",
      imageUrl: "/images/Andrea-Maccagno-Film-Lui-e-Mio-Padre.jpg",
      largeImageUrl: ["/images/Andrea-Maccagno-Film-Lui-e-Mio-Padre-2.jpg"]
      //url: ''
    },
    {
      id: 8,
      title: "Andrea Maccagno Teatro Factory32",
      subtitle: "Prove Reverie @ fACTory32 2020",
      imageUrl: "/images/Andrea-Maccagno-Reverie-Teatro-Factory32.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 9,
      title: "Andrea Maccagno Reload 2017",
      subtitle: "Live @ Reload Music Festival 2017",
      imageUrl: "/images/Andrea-Maccagno-Reaload-Music-Fest-2017.jpg",
      largeImageUrl: 
      [   "/images/Andrea-Maccagno-Reaload-Music-Fest-2017-lg.jpg",
          "/images/Andrea-Maccagno-Reaload-Music-Fest-2017-Tiger-Lily.jpg",
          "/images/Andrea-Maccagno-Reaload-Music-Fest-2017-pubblico.jpg",
          "/images/Andrea-Maccagno-Reaload-Music-Fest-2017-preparativi.jpg",
          "/images/Andrea-Maccagno-Reaload-Music-Fest-2017-Primo-Piano.jpg",
      ]
    },
    {
      id: 10,
      title: "Andrea Maccagno Rai Radio 1",
      subtitle: "Unidea @ Misano 2012",
      imageUrl: "/images/Andrea-Maccagno-Radio-Rai-1.jpg",
      largeImageUrl: 
      [
        "/images/Andrea-Maccagno-Radio-Rai-1-Misano-lg.jpg",
        "/images/Andrea-Maccagno-Radio-Rai-1-Misano-Live.jpg",
        "/images/Andrea-Maccagno-Radio-Rai-1-Fabio-Perversi.jpg",
        "/images/Andrea-Maccagno-Radio-Rai-1-Misano-Prove.jpg"
      ]
    },
    {
      id: 11,
      title: "Andrea Maccagno Cene Di Gala",
      subtitle: "Live @ Villa Castelbarco pre 2016",
      imageUrl: "/images/Villa-Castelbarco-Andrea-Maccagno.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ]
      //url: ''
    },
    {
      id: 12,
      title: "Andrea Maccagno Rock 'n' Wolf",
      subtitle: "Live Reverie @ Power Lights 2020",
      imageUrl: "/images/Andrea-Maccagno-Rock-Wolf.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"]
      //url: ''
    },
    {
      id: 13,
      title: "Ricordi Andrea Maccagno ",
      subtitle: "Vecchi Ricordi @ Prima del 2009",
      imageUrl: "/images/Antonino-Spadaccino-Andrea-Maccagno-Nautilus-Studio.jpg",
      largeImageUrl: 
      [
        "/images/Antonino-Spadaccino-Mario-Lavezzi.jpg",
        "/images/Andrea-Maccagno-John-Giblin-Bassisti.jpg",
        "/images/Andrea-Maccagno-Elio-Rivagli.jpg",
        "/images/Andrea-Maccagno-Ludovico-Vagnone.jpg",
        "/images/Andrea-Maccagno-REM-Studio.jpg"
      ]
      //url: ''
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2009 - Oggi",
        position: "Produttore musicale, arrangiatore, bassita",
        company: "(Tutt'ora mi considero apprendista, in verità)",
        details: "Jordan Rudess (Dream Theater), Nomadi (composizione: 'Abbracciami ancora una volta'), Pooh (tour: 'Dove Comincia Il Sole'), Roby Facchinetti, Piero Cassano (Matia Bazar, Ramazzotti), Carmelo Sorce, Tatiana Anderson, Teatro Ariston - Sanremo, Elio Rivagli, Fabio Perversi (Matia Bazar), Ludovico Vagnone, Roberto Maccagno, Renato Cantele, Piero Vallero, Simone Gaetano, Rafa Vergara, Ricardo Montaner, Rafa Tena, Olga Tanon, Anais, Alexader Pires, Son de Sol, Noella, Estopa, Anje Bao, Antonio Ramos ''El Maca'', Geff, Alessandra Amoroso, Antonino, Jambalaya, Andrea Carta, Paolo Comotti, Alex Cona, Alfredo Grassi, Unidea, Nux, Byro, Asia, Mario Liti, Fabio Venturini, Valerio Villa, Virtual Symmetry, Melania Petrillo, Ruben Paganelli, Michele Scotti, Luigi Baudino, Paolo Capetta, Belzeboss, Piero Cotto, Fabio Cancellara Gomez, Nicolas Rosa, Reload Music Festival 2017, Chiara ''Kik'' La Piana, Raimondo, Giulio ''Giuda'' Nicolosi, Gianluca Teppati, Alessandro Gollessi, Uncle Does, Davide D'urso, Bruno Tripoli, Marco Varisco, Angela Kang, Antonio Papetti, Roberto Gasparro (colonna sonora del film 'Lui è mio padre' con Gianni Parisi e Tony Sperandeo), ecc."
      },
      {
        id: 2,
        year: "2004 - 2009",
        position: "Apprendista",
        company: "REM Sas - REM Studio",
        details: "Periodo in cui ho gettato le fondamenta della mia formazione ed esperienza. Qui ho imparato, innanzitutto, l'umiltà e il professionismo. Ho avuto l'onore di poter imparare da grandi Maestri (vincitori di Grammy Award e Oscar), come Roberto Maccagno, Emanuele Ruffinengo, Danilo Ballo, Mario Lavezzi, Ludovico Vagnone, Alfredo Paixao, Elio Rivagli, Bob Benozzo, Fabio Perversi, John Giblin, ecc.. Ho avuto anche la fortuna di conoscere Grandi Artisti come Chick Corea e Dave Weckl."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2021 - presente",
        graduation: "MFH Srl - T&T Srl",
        university: "Amministratore delegato e titolare",
        //details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2009 - 2021",
        graduation: "ART360 di Maccagno Andrea",
        university: "Titolare",
        //details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2004 - 2009",
        graduation: "REM Sas - Rem Studio",
        university: "Collaboratore",
        //details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Creare un business da zero (by Andrea Maccagno)',
      featuredImage: '/images/Creare-Business-da-zero-Andrea-Maccagno.jpg',
      filesource: '../../blog/creare-un-business-da-zero.md',
      createDay: "4",
      createMonth: 'Aprile',
      createYear: "2016"
    },
    {
      id: 2,
      title: 'Successo nella musica: primo ingrediente (by Andrea Maccagno)',
      featuredImage: '/images/Successo-nella-Musica-Andrea-Maccagno.jpg',
      filesource: '../../blog/successo-musica-1.md',
      createDay: "14",
      createMonth: 'Aprile',
      createYear: "2016"
    },
    {
      id: 3,
      title: 'Intervista ad Andrea Maccagno (by Roberta Ferrino)',
      featuredImage: '/images/Intervista-Andrea-Maccagno.jpg',
      filesource: '../../blog/intervista-ad-andrea-maccagno.md',
      createDay: "4",
      createMonth: 'Aprile',
      createYear: "2016"
    },
    {
      id: 4,
      title: 'Sanremo: nuova sigla per il Festival? (by Andrea Maccagno)',
      featuredImage: '/images/Giuda-Nicolosi-Andrea-Maccagno.jpg',
      filesource: '../../blog/sanremo-giuda-nicolosi-sigla-del-festival.md',
      createDay: "7",
      createMonth: 'Febbraio',
      createYear: "2018"
    },
    {
      id: 5,
      title: 'Still life fai-da-te: ottenere la foto perfetta. (by Andrea Maccagno)',
      featuredImage: '/images/Still-life-Andrea-Maccagno-Stormtrooper-Star-Wars-2.jpg',
      filesource: '../../blog/still-life-fotografia.md',
      createDay: "6",
      createMonth: 'Luglio',
      createYear: "2016"
    },
    {
      id: 6,
      title: "La rubrica degli artisti: L'arte secondo Franco Farina",
      featuredImage: '/images/Due-universi-Franco-Farina-Andrea-Maccagno.jpg',
      filesource: '../../blog/arte-franco-farina.md',
      createDay: "23",
      createMonth: 'Luglio',
      createYear: "2016"
    },
    {
      id: 7,
      title: 'La rubrica degli artisti: Starlights Band',
      featuredImage: '/images/Starlighs-Band-Andrea-Maccagno.jpg',
      filesource: '../../blog/nuovo-sito-band-starlights.md',
      createDay: "23",
      createMonth: 'Settembre',
      createYear: "2018"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+39 3934253474'],
    emailAddress: ['info@andreamaccagno.com'],
    address: "mfh.one" +", "+ "varp-studio.com"+ ", " + "andreamaccagno.com",
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});